@media (min-width: 200px) {
  .art-space {padding-top: 0px; padding-bottom: 0px;} /*1rem = 16px*/
}

@media (min-width: 578px) {
  .art-space { padding-top: 12px; padding-bottom: 12px;}
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .art-space {padding-top: 0px; padding-bottom: 0px;}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .art-space {padding-bottom: 0px;}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .art-space {padding-bottom: 0px;}
}