.Pricing {
  &__period {
    opacity: 0.4;
  }

  &__no-classname {
    font-size: 20px;
    padding-left: 5px;
  }
}
