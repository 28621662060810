.menu-item {
  padding: 0 40px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;

  &.active {
    border: 1px green solid;
  }
}
.menu-item-wrapper.active {
  border: 1px blue solid;
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}