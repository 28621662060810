.AspectRatio {
  position: relative;
  width: 100%;
  height: 0;

  &__inner {
    // Fill parent aspect ratio
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // Center contents
    display: flex;
    align-items: center;
    justify-content: center;
    // overflow: hidden;
  }
}
