body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
}

.header {
  padding: 80px;
  text-align: center;
  // color: white;
  background-size: cover;
}

.header h1 {
  font-size: 40px;
}

.filters .navbar {
  overflow: hidden;
  //  background: #269faf;
}

.filters .navbar a {
  float: left;
  display: block;
  // color: white;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
}

.filters .navbar a.right {
  float: right;
}

.filters .navbar a:hover {
  // background-color: #ddd;
  color: black;
}

.row {
  display: -ms-flexbox;
  display: flex;
}

.side {
  -ms-flex: 30%;
  flex: 30%;
  background-color: #f1f1f1;
  padding: 20px;
}

.main {
  -ms-flex: 70%;
  flex: 70%;
  background-color: white;
  padding: 20px;
}

.fakeimg {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 4px;
}

.footer {
  padding: 20px;
  text-align: center;
  background: #ddd;
}

.sticky {
  padding: 0%;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.5s ease;
  animation: smoothScroll 1s forwards;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}
