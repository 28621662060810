.Features {
  max-width: 900px;
  margin: 60px auto 0 auto;

  .row {
    // Reverse every other row
    &:nth-of-type(even) {
      flex-direction: row-reverse;
    }

    &:not(:last-of-type) {
      padding-bottom: 1.5rem;
      @media screen and (min-width: 769px) {
        padding-bottom: 2.5rem;
      }
    }
  }

  &__image-container {
    max-width: 300px;
    margin: 30px auto;
  }
}
