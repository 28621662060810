@media (min-width: 200px) {
  .fonty {font-size: 0.7rem;} /*1rem = 16px*/
}

@media (min-width: 544px) {
  .fonty {font-size: 1rem;} /*1rem = 16px*/
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .fonty {font-size:1rem;} /*1rem = 16px*/
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .fonty {font-size:1rem;} /*1rem = 16px*/
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .fonty {font-size:1rem;} /*1rem = 16px*/
}
