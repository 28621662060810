.ModalLauncher {
  font-size: 100px;
  &__redColor {
    color: red;
  }

  &__orangeColor {
    color: orange;
  }

  &__yellowColor {
    color: yellow;
  }

  &__greenColor {
    color: green;
  }

  &__blueColor {
    color: blue;
  }

  &__purpleColor {
    color: purple;
  }

  &__pinkColor {
    color: pink;
  }

  &__brownColor{
    color: brown;
  }

  &__greyColor {
    color: grey;
  }

  &__blackColor {
    color: black;
  }

  &__whiteColor {
    color: white;
  }

  &__no-classname {
    font-size: 100px;
  }
}
