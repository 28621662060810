.ModalLauncher2 {
  &__no-classname {
    color: red;
  }

  &__no-classname {
    color: orange;
  }

  &__no-classname {
    color: yellow;
  }

  &__no-classname {
    color: green;
  }

  &__no-classname {
    color: blue;
  }

  &__no-classname {
    color: purple;
  }

  &__no-classname {
    color: brown;
  }

  &__no-classname {
    color: grey;
  }

  &__no-classname {
    color: black;
  }

  &__no-classname {
    color: white;
  }

  &__no-classname {
    font-size: 45px;
  }
}
